.enrollmentForm-container {
  width: 100vw;
  max-width: 100%;
  min-height: 80vh;
}

.enrollmentForm-contentWrapper {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 5px;
  backdrop-filter: blur(6px);
  border-radius: 0.375rem;
  box-shadow: -4px 8px 5px 0px rgba(0,0,0,0.17);
  transition: all 1s ease-in 0.3s;
}


@media screen {
  @media (min-width: 600px) {
    .enrollmentForm-container {
      width: 80vw;
    }
    .enrollmentForm-contentWrapper {
      padding: 15px;
    }
  }

  @media (min-width: 1000px) {
    .enrollmentForm-container {
      width: 60vw;
      padding: 30px;
    }
  }
}
