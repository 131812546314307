body {
  margin: 0;
}

.pageContainer {
  position: relative;
  min-height: 100vh;
}

.contentWrapper {
  position: relative;
  padding: 20px 20px 80px;
  margin-top: $pageOffset_top;
  min-height: calc(100vh - #{$pageOffset_top});
}

.footer {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px 15px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  &-logo {
    display: block;
    max-height: 20px;
    object-fit: contain;
    object-position: center;
  }

  &-copyright {
    font-size: 0.7rem;
  }
}

@media screen {
  @media only (min-width: $breakpoint_medium) {
    .footer {
      &-wrapper {
        flex-direction: row;
        justify-content: space-between;
      }
      &-copyright {
        position: absolute;
        right: 10px;
        bottom: 12px;
      }
    }
  }
}
