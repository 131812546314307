.MuiFormControl-root {
  position: relative;
}


.MuiFormControl-root {

  &:hover {
    & > .MuiOutlinedInput-notchedOutline.custom {
      border-color: black;
      &.disabled {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }
}

.customMuiGroup-fieldset {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 20px;
  border-radius: 4px;
  display:  block;
  position: absolute;
  bottom: -1px;
  right: -3px;
  top: -7px;
  left: -3px;
  width: 100%;
  height: 63px;
  &.disabled {
    border: 1px solid rgba(60, 60, 60, 0.23);
    &:hover {
      border: 1px solid rgba(60, 60, 60, 0.23);
    }
  }

  .customMuiGroup-content {
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
    &:focus, &:focus-within {
      z-index: 2;
    }
  }

  & > legend {
    color: rgba(0,0,0,0.6);
    font-size: 1rem;
    margin-left: -14px;
    transform: scale(0.75);
    visibility: hidden;
    &:disabled {
      color: rgba(60, 60, 60, 0.6);
    }
  }

  & > label {
    --Grid-columns: 12;
    --Grid-columnSpacing: 16px;
    --Grid-rowSpacing: 16px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: -2px;
    top: -10px;
    transform: translate(14px, -9px) scale(0.75);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    user-select: none;
    &:disabled {
      color: rgba(60, 60, 60, 0.6);
    }
  }
}

.customMuiGroup-outlined {
  &:hover {
    & .customMuiGroup-fieldset {
      border-color: black;
      &.disabled {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }
  &:focus-within {
    & .customMuiGroup-fieldset {
      border-color: $primary;
      border-width: 2px;
      top: -7px;
      left: -3px;
      & > label {
        left: -1px;
      }
      & > .customMuiGroup-content {
        left: -1px !important;
        right: -1px !important;
      }
    }
  }
}

.customMuiGroup-fieldset.disabled {
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiOutlinedInput-input {
  margin-top: 1px;
}
.MuiTelInput-TextField .MuiOutlinedInput-input {
  margin-left: -57px !important;
  padding-left: 57px !important;
  margin-top: 0;
}
