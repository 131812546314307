//------------[Breakpoints]-------------//

//grid
$breakpoint_small: 32.5em;
$breakpoint_medium: 767px;
$breakpoint_mediumLarge: 90em;
$breakpoint_Large: 130em;
$breakpoint_xLarge: 160em;


//----------------[colors]----------------//

//colorTheme
$white: #ffffff;
$black: #252525;
$primary: #43c1e8;
$success: #27c24c;
$danger: #f05050;
$warning: #ff902b;
$text_primary: #383737;
$text_secondary: #acb9be;

//background colors
$backgroundColor_main: #fbfbfb;


$bg-dark: #131e26;
$bg-red: #f05050;
$bg-red-darker: #ec2121;
$bg-red2: #ce4550;
$bg-red2-darker: #c74040;
$bg-blue: #5d9cec;
$bg-blue-darker: #3e699f;
$bg-blue2: #5d75ec;
$bg-blue2-darker: #3e559f;
$bg-green: #30b750;
$bg-green-darker: #2b9344;
$bg-green2: #39af6c;
$bg-green2-darker: #26a25c;
$bg-grey: #7c7c7c;
$bg-grey-darker: #555555;
$bg-darkgrey: #262626;
$bg-darkgrey-darker: #1e1e1e;
$bg-yellow: #ffde14;
$bg-yellow-darker: #ac950c;
$bg-yellow2: #ffeb14;
$bg-yellow2-darker: #aca40c;
$bg-orange: #ff902b;
$bg-orange-darker: #f77600;
$bg-orange2: #ff6b2b;
$bg-orange2-darker: #f74a00;
$bg-cyan: #2ce3b2;
$bg-cyan-darker: #199b78;
$bg-cyan2: #39af99;
$bg-cyan2-darker: #26a27d;
$bg-purple: #6d27e5;
$bg-purple-darker: #3e199b;
$bg-purple2: #9027e5;
$bg-purple2-darker: #58199b;


//global colors
$footerColor: none;
$dropShadowColor_main: rgba(0, 0, 0, 0.3);

//buttons
$btn-primary: #ef6e2d;
$btn-secondary: #3d3d3d;
$btn-danger: #f05050;
$btn-success: #27c24c;
$btn-warning: #fab505;

//Navigation color
$navColor: #f6f6f6;
$navFontColor: $black;

//font colors

$page-heading-color: #5e5e5e;

//border
$border_color: #ceced3;


//----------------[fonts]----------------//
$font_main: 'Bellota Text', sans-serif;


//----------------[Sizes]----------------//

//scaling
$scale1: scale(1.01);
$scale2: scale(1.1);

//border
$borderRadius: 0;
$borderSize: 2px;
$borderSize_hover: 3px;


//buttons
$buttonWidth: 200px; //fancy buttons
$buttonHeight: 55px; //fancy buttons

//icon sizes
$iconSize1: 30px;
$iconSize2: 40px;

//navigation
$navHeight_desktop: 55px;
$sideNav_width: 220px;
$sideNav_width--min: 70px;

//Navitems
$navLink_size: 30px; //nav items size

//Navlogo
$navLogoSize: $iconSize1; //nav logo size
$navLogoSize_max: 70px; //nav logo max size

//pageLayout


//pageSizes
$pagePWP_small: 95vw; //small screen width %
$pagePWP_medium: 90vw; //large screen width %

$pageW_small: 800px; //small section width
$pageW_medium: 1200px; //medium section width
$pageW_large: 1600px; //Large section width
$pageW_xLarge: 2200px; //X-large section width

//hero
$heroHeight: 60vh;
$heroHeight_max: 650px;

//content sizes
$content_fullPage: 100vw;

//footer
$footerHeight_base: 150px;


//----------[Typography]-----------//
$fontSize_base: 12px;
$fontSize_modifier: 6px;

$baseFontSize: 1rem;
$fontSize1: 1rem;
$fontSize2: 1.25rem;
$fontSize3: 1.5rem;
$fontSize4: 1.75rem;

//side menu
$sideHeader_size: 0.9rem;


//----------[Time]-----------//
$animationSpeed1: 200ms;
$animationSpeed2: 250ms;
$animationSpeed3: 300ms;
$animationSpeed4: 400ms;


//----------[Margins/Paddings]-----------//

//global margins
$gMargin: 1rem;
$spacing1: 0.8rem;
$spacing2: 1.5rem;
$spacing3: 2.5rem;
$spacing4: 4.5rem;
$spacing5: 6rem;

//Page margin
$pageOffset_top: 60px; //spacing over content on pages
$pageOffset_left: $sideNav_width; //spacing left of content full
$pageOffset_left--min: $sideNav_width--min; //spacing left of content minimized

//sections
$sectionPadding: $spacing3 0 $spacing5; //spacing on sections

//Navbar
$navbarPadding: 0.5rem; //navbar padding
$navItemMargins: auto 0.5rem; //margin between items & centration of items vertically
$navFontSize: 0.65rem;

//button
$btnPadding: 0.75rem 2rem; //padding for buttons


//----------------[styling]----------------//

//box styling
$dropShadow: 0 1px 5px $dropShadowColor_main;
$activeDropShadowBlack: 0 10px 15px $dropShadowColor_main;

//buttons styling


//----------------[animations]----------------//
@mixin sideMenuAnimation($action) {
  @if $action == "min" {

  }
  animation: ease-in;
  animation-delay: 0.5ms;

  @keyframes minimize {
    from {
      width: $sideNav_width
    }
    to {
      width: $sideNav_width--min
    }
  }

}
