@import "./settings";

*, *::before, *::after {
  box-sizing: border-box;
}

.body {
  color: $text_primary
}

.closeButton {
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 0;
}

.reloadButton {
  &.loading {
    & > svg {
      animation: loading-reload 1s linear infinite;
    }
  }
}

a.link, a {
  color: $primary;

  &:hover {
    color: #b21818;
  }
}

@mixin borderTop {
  &:before {
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, $primary, $primary);
    content: "";
    margin: 0;
    border-radius: inherit;
  }
}

@mixin borderBottom {
  &:after {
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, $primary, $primary);
    content: "";
    margin: 0;
    border-radius: inherit;
  }
}

span.br {
  display: block;
}

span.nobr {
  white-space: nowrap;
}

@keyframes loading-reload {
  to {
    transform: rotate(360deg);
  }
}
